import React, { useState, useEffect } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Articles.scss';

const Article = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [articles, setArticles] = useState([]);
  const [brands, setBrands] = useState([]);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const query = '*[_type == "articles"]';
    const brandsQuery = '*[_type == "brands"]';

    client.fetch(query).then((data) => {
      setArticles(data);
    });

    client.fetch(brandsQuery).then((data) => {
      setBrands(data);
    });
  }, []);

  return (
    <>
    <h2 className="head-text title">Articles</h2>
      {articles.length && (
        <>
          <div className="app__article-item app__flex">
            <img className='article-cover-img' src={urlFor(articles[currentIndex].imgurl)} alt={articles[currentIndex].name} />
            <div className="app__article-content">
              <div>
                <h4 className="bold-text">{articles[currentIndex].name}</h4>
                <h5 className="p-text">Published On: {articles[currentIndex].company}</h5>
                <p className="summary">{articles[currentIndex].summary}</p> 
                <a href={articles[currentIndex].articleLink}
                  target="_blank" 
                  rel="noopener noreferrer"  
                  className='continue'
                >
                  Continue reading ➜
                </a>
              </div>
            </div>
          </div>

          <div className="app__article-btns app__flex">
            <div className="app__flex" onClick={() => handleClick(currentIndex === 0 ? articles.length - 1 : currentIndex - 1)}>
              <HiChevronLeft />
            </div>

            <div className="app__flex" onClick={() => handleClick(currentIndex === articles.length - 1 ? 0 : currentIndex + 1)}>
              <HiChevronRight />
            </div>
          </div>
        </>
      )}

      <div className="app__article-brands app__flex">
        {brands.map((brand) => (
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, type: 'tween' }}
            key={brand._id}
          >
            {/* <img src={urlFor(brand.imgUrl)} alt={brand.name} /> */}
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Article, 'app__article'),
  'articles',
  'app__primarybg',
);