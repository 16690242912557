import React from 'react' 
import { FaMedium, FaGithub, FaLinkedin } from 'react-icons/fa';
import { IconContext } from 'react-icons'; 

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <IconContext.Provider value={{ color: "black", className: "social-media-icons", size: "5em" }}> 
            <div>
                <a href='https://www.linkedin.com/in/elisabethjoyashley' target='_blank'  rel='noopener noreferrer' >
                    <FaLinkedin />
                </a>
            </div>
            <div>
                <a href='https://medium.com/@elisabethashley' target='_blank'  rel='noopener noreferrer' >
                    <FaMedium />
                </a>
            </div>
            <div>
                <a href='https://github.com/eashl003' target='_blank'  rel='noopener noreferrer' >
                    <FaGithub />
                </a>
            </div>
        </IconContext.Provider>
    </div>
  )
}

export default SocialMedia